"use client";

import "./styles.scss";

export const AccordionCSS = ({ title, index, customId, children }) => {
  return (
    <div
      data-testid="accordion"
      className="accordion-css collapsible-accordion-css">
      <div className="css-collapsible-item">
        <input
          type="checkbox"
          id={`accordion-${index}${customId}`}
          name="accordion-input"
          defaultChecked={index == 0}
          className="accordion-input"
        />
        <label
          className="css-collapsible-item-label accordion-css__title"
          htmlFor={`accordion-${index}${customId}`}>
          {title}
        </label>
        <div className="css-collapsible-item-content accordion-css__content">
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};
