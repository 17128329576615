"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import { useWindowSize } from "@/hooks/useWindowsSize";
import { Typography } from "../Typography";
import { CardItem, CardListProps } from "./types";
import { Chip } from "../Chip";

import "swiper/css";
import "./styles.scss";
import "swiper/css/scrollbar";

export const CardList = (props: CardListProps) => {
  const { cardItems, showCarrousel } = props;

  const screenSize = useWindowSize();

  const [component, setComponent] = React.useState(<div />);

  const generateCard = (item: CardItem, idx?: number) => {
    const { icon, onClick, subtitle, title, component, label } = item;

    return (
      <div
        key={idx}
        data-testid={`card-list-item-${idx}`}
        className={`u-box u-shadow-small card-item ${component ? "hasComponent" : ""}`}
        onClick={() => onClick && onClick({ index: idx, title: title })}>
        <div className={`card-container`}>
          <div className="u-flex card-item--header">
            {icon}
            {(title ?? typeof title === "string") ? (
              <Typography
                Tag="p"
                size="s">
                {title}
              </Typography>
            ) : (
              title
            )}
          </div>
          <div className="card-item--body">
            <>
              {(subtitle ?? typeof subtitle === "string") ? (
                <Typography
                  Tag="p"
                  size="xs">
                  {subtitle}
                </Typography>
              ) : (
                subtitle
              )}
            </>
          </div>
        </div>
        {component === "chip" && (
          <Chip
            label={label}
            type="outlined"
            color="success"
          />
        )}
      </div>
    );
  };

  const getGrid = () => {
    return (
      <div
        data-testid="card-list"
        className="card-items-container">
        {cardItems.map(generateCard)}
      </div>
    );
  };

  const getCarousel = () => {
    const Swp = Swiper as any;
    return (
      <div
        data-testid="card-list"
        className="card-items-container">
        <Swp
          scrollbar={{
            hide: false,
            horizontalClass: "card-list-swiper--scrollbar-horizontal",
            dragClass: "card-list-swiper--scrollbar-drag",
          }}
          slidesPerView={"auto"}
          modules={[Scrollbar]}
          className="card-list-swiper">
          {cardItems.map((item, idx) => (
            <SwiperSlide key={idx}>{generateCard(item, idx)}</SwiperSlide>
          ))}
        </Swp>
      </div>
    );
  };

  React.useEffect(() => {
    if (screenSize?.isMobile && showCarrousel) {
      const carousel = getCarousel();
      setComponent(carousel);
    } else {
      const grid = getGrid();
      setComponent(grid);
    }
  }, [screenSize?.isMobile]);

  return component;
};
