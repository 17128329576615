import { AccordionCSS } from "../AccordionCSS";
import { Typography } from "../Typography";

export const AccordionTabContent = ({ item, index, customId }) => {
  return (
    <AccordionCSS
      title={item.title}
      index={index}
      customId={`-${customId}`}>
      {item.richtext.map((item, i) => (
        <Typography
          key={i}
          Tag={item.tag}>
          {item.list
            ? item.list.map((e, i) => (
                <li key={i}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: e["item"] as string,
                    }}></span>
                </li>
              ))
            : item.content}
        </Typography>
      ))}
    </AccordionCSS>
  );
};
