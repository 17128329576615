"use client";
import React from "react";

import { Typography } from "../Typography";
import { Banner } from "@/components/Banner";
import { Link } from "../Link";
import { Tabs } from "../Tabs";
import { TabsModuleProps } from "./types";

import "./styles.scss";

export const TabsModule = ({
  data,
  wrapperSize,
  backgroundColor,
  className,
  setSource,
}: TabsModuleProps) => {
  const { title, faqsHomeTabs, actionButton, customClass, amplitudeEvent } = data;
  return (
    <Banner
      big
      customBgColor={backgroundColor}
      wrapperSize={wrapperSize}
      customClass={`banner-tabs ${customClass}`}>
      <Typography
        Tag={"h2"}
        spacing={6}>
        {title}
      </Typography>
      <Tabs
        tabsData={faqsHomeTabs}
        className={className}
      />
      {actionButton && (
        <Link
          href={actionButton.href}
          className="bold-text large button action-button tabs"
          type="button"
          onClick={() => amplitudeEvent && setSource(amplitudeEvent)}
          nofollow>
          {actionButton.label}
        </Link>
      )}
    </Banner>
  );
};
